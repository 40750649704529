import React, { useState, useEffect, useRef } from 'react';
import InputBox from '../InputBox';
import { currentFiscalYear, isValidDate, userDetail } from '../Variables/TextVariables';
import TableEntry from '../ParagraphItems/TableEntry';
import { participantsDetails } from '../Variables';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { database } from '../ServerWorks/firebase';

const Baithak = (props) => {
    const baithakDatas = props.data.data;
    const topic = props.title;
    const FiscalYear = currentFiscalYear();
    const databaseChainName = userDetail().district + "/" + userDetail().municipality.नाम + "/" + userDetail().office.प्रकार + userDetail().office["वडा नं"];
    const componentRef = useRef();
    const date = props.date;
    const specificItems = props.specificItems.items;
    var topicObjects = specificItems[topic];
    if (typeof topicObjects === 'undefined') topicObjects = {};

    const [meetingDetails, setMeetingDetails] = useState({
        title: '',
        date: '',
        chairperson: '',
        post_chairperson: '',
    });

    const updater = (field, value) => {
        setMeetingDetails(prevDetails => ({
            ...prevDetails,
            [field]: value,
        }));
    };
    const [participants, setParticipants] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [topics, setTopics] = useState([]);
    const [introText, setIntroText] = useState('');
    const [table, setTable] = useState([]);

    const baithakUpdater = (field, value) => {
        const updatedValue = {};
        updatedValue[field] = value;
        props.specificItems.updater(topic, { ...specificItems[topic], ...updatedValue });
    };
    const setDefaultParticipants = () => {
        console.log("set defaults called");
        // make sure multiple partcipants can also be saved providing name as label and participants list.
        console.log(topicObjects.participants);
        const documentRef = doc(database, databaseChainName, "baithakDefaultParticipants");
        setDoc(documentRef, { participants: topicObjects.participants })
            .then(() => {
                console.log("defaults are set ");
            })
            .catch((error) => {
                alert(error.message);
            })

    }
    const loadDefaultParticipants = () => {
        const documentRef = doc(database, databaseChainName, "baithakDefaultParticipants");
        getDoc(documentRef).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.data());
                let defaultParticipants = snapshot.data();

                props.specificItems.updater(topic, { ...specificItems[topic], ...defaultParticipants });
            } else {
                console.log("no such snapshot");
            }
        })
            .catch((error) => {
                alert(error.message);
            });
    }
    // useEffect(() => {
    //     if (!topicObjects.participants || topicObjects.participants.length === 0) {
    //         loadDefaultParticipants();
    //     }
    // }, [topicObjects.participants]);

    const participantsInput = (
        <div>
            <h4>Participants</h4>
            <button onClick={loadDefaultParticipants} id='loadParticipantsDefaultsButton'>Load Default Participants</button>

            <TableEntry borderless={true} setTable={setTable} inputHeads={participantsDetails} variableName="participants" details={topicObjects} detailsUpdater={baithakUpdater} />
            <button onClick={setDefaultParticipants} id='setParticipantsDefaultsButton'>Set Default Participants</button>
        </div>
    );
    // useEffect(() => {
    //     // Fetch default participants and previous meetings from the database
    //     fetchParticipants();
    // }, []);

    // const fetchParticipants = async () => {
    //     // Fetch participants from your database API
    //     const data = await fetch('/api/participants'); // Replace with actual API
    //     const participants = await data.json();
    //     setParticipants(participants);
    // };

    const handleAddTopic = () => {
        setTopics([...topics, { name: '', details: '', decision: '' }]);
    };

    const handleSaveMeeting = async () => {
        const meetingData = {
            ...meetingDetails,
            participants: selectedParticipants,
            topics,
        };
        await fetch('/api/saveMeeting', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(meetingData),
        });
    };
    const inputs = Object.keys(meetingDetails).map((item, index) => {
        return (
            <div className="col" key={index}>
                <InputBox
                    field={item}
                    value={meetingDetails[item]}
                    required={true}
                    handleChanges={(field, value) => {
                        updater(field, field.endsWith("मिति") ? isValidDate(value) : value);
                    }
                    }
                />
            </div>
        );
    });


    return (
        <div className='mukesh'>
            <h2>Meeting Minutes</h2>
            <div className="row">
                {inputs}
            </div>
            {participantsInput}
            <form onSubmit={handleSaveMeeting}>

                <h3>Select Participants</h3>
                <ul>
                    {/* {participants.map((participant) => (
                        typeof participant !==
                        <li key={participant.id}>
                            <input
                                type="checkbox"
                                checked={selectedParticipants.includes(participant)}
                                onChange={() =>
                                    setSelectedParticipants((prev) =>
                                        prev.includes(participant)
                                            ? prev.filter((p) => p !== participant)
                                            : [...prev, participant]
                                    )
                                }
                            />
                            {participant.name} - {participant.post}
                        </li>
                    ))} */}
                </ul>

                <h3>Topics to Discuss</h3>
                <button type="button" onClick={handleAddTopic}>
                    Add Topic
                </button>
                {topics.map((topic, index) => (
                    <div key={index}>
                        <label>
                            Topic Name:
                            <input
                                type="text"
                                value={topic.name}
                                onChange={(e) => {
                                    const newTopics = [...topics];
                                    newTopics[index].name = e.target.value;
                                    setTopics(newTopics);
                                }}
                            />
                        </label>
                        <label>
                            Details:
                            <textarea
                                value={topic.details}
                                onChange={(e) => {
                                    const newTopics = [...topics];
                                    newTopics[index].details = e.target.value;
                                    setTopics(newTopics);
                                }}
                            />
                        </label>
                        <label>
                            Decision:
                            <textarea
                                value={topic.decision}
                                onChange={(e) => {
                                    const newTopics = [...topics];
                                    newTopics[index].decision = e.target.value;
                                    setTopics(newTopics);
                                }}
                            />
                        </label>
                        <button
                            type="button"
                            onClick={() =>
                                setTopics(topics.filter((_, i) => i !== index))
                            }
                        >
                            Remove Topic
                        </button>
                    </div>
                ))}

                <button type="submit">Save Meeting</button>
            </form>

            <div>
                <h3>Meeting Summary</h3>
                <p>
                    Today, under the chairmanship of {meetingDetails.chairperson}, this
                    meeting was conducted with the following participants:
                </p>
                <ul>
                    {selectedParticipants.map((participant) => (
                        <li key={participant.id}>
                            {participant.name} - {participant.post} - Signature ________
                        </li>
                    ))}
                </ul>
                <h4>Topics Discussed</h4>
                <ul>
                    {topics.map((topic, index) => (
                        <li key={index}>
                            <strong>{topic.name}</strong>: {topic.details} <br />
                            **Decision**: {topic.decision}
                        </li>
                    ))}
                </ul>
            </div>
            <div>

                <h2>१. बैठकको नामः {meetingDetails.title}</h2>
                <p><strong>२. बैठकको अध्यक्षता गर्ने व्यक्तिः</strong> श्री {meetingDetails.chairperson}</p>
                <p><strong>३. बैठकको अध्यक्षता गर्ने व्यक्तिको पदः</strong> {meetingDetails.post_chairperson}</p>

                <h3>४. बैठकको उपस्थितिः</h3>
                {table}
                <table border="1">
                    <thead>
                        <tr>
                            <th>क्र.सं</th>
                            <th>नाम</th>
                            <th>पद</th>
                            <th>हस्ताक्षर</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>१</td>
                            <td>श्री अशोक कुमार साहु तेली</td>
                            <td>वडा अध्यक्ष</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>२</td>
                            <td>श्रीमती देवी महतो</td>
                            <td>महिला वडा सदस्य</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>३</td>
                            <td>श्रीमती पचिया देवी सदा</td>
                            <td>दलित महिला वडा सदस्य</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>४</td>
                            <td>श्री माधव प्रसाद मण्डल</td>
                            <td>वडा सदस्य</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>५</td>
                            <td>श्री देव नारायण मेहता</td>
                            <td>वडा सदस्य</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>६</td>
                            <td>श्री मुकेश पोखरेल</td>
                            <td>वडा सचिव</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <h3>प्रस्ताव तथा छलफलका विषयहरु</h3>
                <ol>
                    <li>फुटकर मर्मत सम्भार सम्बन्धमा ।</li>
                    <li>योजना सम्बन्धमा ।</li>
                    <li>युवा तथा खेलकुद सम्बन्धमा ।</li>
                </ol>

                <h3>निर्णयहरु</h3>

                <h4>निर्णय नं १</h4>
                <p>प्रस्ताव नं १ माथि छलफल गर्दा आ.व ०८०।०८१ मा वडा कार्यलय मार्फत संचालन हुने अति आवश्यक सानातिना सडक मर्मत तथा फुटकर योजनामा विनियोजित बजेट रकम बाट मिति २०८०।१०।१४ गते बसेको यस वडा नं ८ को वडा समितिको निर्णय नं १ को योजना नं १ र ४ को ठाउँमा तल बमोजिम मर्मत सम्भार गर्ने निर्णय पारित गरियो ।</p>
                <ul>
                    <li>रजेलाल उराउको टोलमा माटो र ग्राभेल गर्ने कार्य।</li>
                    <li>डम्बर कार्कीको घरदेखि पूर्व नहर जोड्ने बाटोमा माटो र ग्राभेल गर्ने कार्य।</li>
                </ul>

                <h4>निर्णय नं २</h4>
                <p>प्रस्ताव नं २ माथि छलफल गर्दा आ.व. ०८०।०८१ मा वडा कार्यालय मार्फत संचालन हुने तलका योजनाहरु संसोधन प्रस्ताव नगरपालिकामा पेश गर्ने निर्णय गरियो ।</p>
                <table border="1">
                    <thead>
                        <tr>
                            <th>हालको योजनाको नाम</th>
                            <th>रकम</th>
                            <th>संसोधन गरिएको योजना</th>
                            <th>रकम</th>
                            <th>कैफियत</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>सत्खोलियाको डोमि उरावको घर हुँदै रनकाली थानसम्म नया बाटो निर्माण र २ थान होम पाइप जडान</td>
                            <td>१,५०,०००।००</td>
                            <td>स्थानिय बजार व्यवस्थापन तथा बिस्तार</td>
                            <td>२,००,०००।००</td>
                            <td>रु. ५०,००० मा रु. १,५०,००० थप गर्ने</td>
                        </tr>
                    </tbody>
                </table>

                <h4>निर्णय नं ३</h4>
                <p>प्रस्ताव नं २ माथि छलफल गर्दा आ.व. ०८०।०८१ मा वडा कार्यालय मार्फत संचालन हुने सरस्वती स्कुल देखि पश्चिम दक्षिण जाने कृषि सडकमा माटो पुरी ग्राभेल तथा स्तरोन्नती र गणेशपुरको सानो मुसहरीको भित्री सडकमा सिमेन्टको ब्लक हाली बाटो स्तरोन्नती तथा निर्माण गर्ने योजनाहरु टेन्डर मार्फत सञ्चालन भएको हुनाले उक्त योजनाहरुमा विनियोजित रकमबाट बाँकी रकम गणेशपुरको सानो मुसहरीको भित्री सडकमै रकम थप गरी थप सिमेन्टको ब्लक हाली बाटो स्तरोन्नती तथा निर्माण गर्ने र सो को प्राविधिक लागत स्टमेट समेत गरी योजना सञ्चालन गर्ने निर्णय गरियो ।</p>

                <h4>निर्णय नं ४</h4>
                <p>प्रस्ताव नं ३ माथि छलफल गर्दा आ.व २०८०।०८१ मा वडा कार्यालय मार्फत संचालन हुने युवा तथा बालबालिकाहरुलाई खेलकुद सामग्री वितरण तथा खेलकुद विकास कार्यक्रम वडामा भएका विद्यालयहरुमा र समुदायबाट माग भएअनुसार निवेदनका आधारमा खेलकुद सामग्री वितरण गरी कार्यकमा सञ्चालन गर्ने निर्णय पारित गरियो ।</p>

            </div>
        </div>
    );
};

export default Baithak;
