import React, { useState, useEffect } from 'react';
import InputBox from '../InputBox';
import SelectBox from '../SelectBox';
import { arrayToObjects, spaces, tippaniItems, tippaniPeople } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';
import SelectiveInputs from './SelectiveInputs';
import './TableTippani.css'; // Import the CSS file for styling


export default function TableEntry(props) {
    const { details, inputHeads, defaults, variableName = "inputobjects", numberIndex = [] } = props;

    const initialDefaultDatas = defaults;
    let initializationObject = arrayToObjects(inputHeads, initialDefaultDatas);
    const inputobjects = details[variableName] ? details[variableName] : [initializationObject];
    console.log({ inputobjects });
    const [count, setCount] = useState(inputobjects.length); // this is total items count
    const inputs = [];
    var objectcounts = 0;

    while (count > objectcounts) {
        let objectcount = objectcounts;
        let x = inputobjects[objectcount] ? "" : inputobjects.push(initializationObject);
        inputs.push(
            <div className='row' key={inputs.length}>
                <div>
                    क्र.स. {objectcount + 1}
                </div>

                {inputHeads.map((items, index) => {
                    return <div className="col" key={index}>
                        {items.includes("/") ? [0].map(() => {
                            let parts = items.split(" ")[0].split("/");
                            let suffix = items.substring(items.indexOf(" ") + 1);
                            let options = [items, ...parts.map(part => part + suffix)];
                            return <SelectBox key={index + "_"} value={items} options={options} handleChanges={
                                (value) => {
                                    let arr = [...inputHeads];
                                    inputHeads[index] = value;
                                    arr[index] = value;
                                }} />
                        }) : ""}
                        {items.includes(',') ? [0].map(() => {
                            let subItems = items.split(",");
                            return <div key={index}>{subItems.map((items1, index1) => {
                                return <div key={index1}>
                                    {numberIndex.includes(index) ?
                                        <InputBox type="number" field={items1} value={inputobjects[objectcount][items1]} handleChanges={(field, value) => {
                                            if (field.includes("मिति")) value = isValidDate(value);
                                            inputobjects[objectcount][field] = value;
                                            props.detailsUpdater(variableName, [...inputobjects])
                                        }} />
                                        :
                                        <InputBox field={items1} value={inputobjects[objectcount][items1]} handleChanges={(field, value) => {
                                            if (field.includes("मिति")) value = isValidDate(value);
                                            inputobjects[objectcount][field] = value;
                                            props.detailsUpdater(variableName, [...inputobjects])
                                        }} />
                                    }
                                </div>
                            })
                            }</div>
                        }) : numberIndex.includes(index) ?
                            <InputBox type="number" field={items} value={inputobjects[objectcount][items]} handleChanges={(field, value) => {
                                inputobjects[objectcount][field] = value;
                                props.detailsUpdater(variableName, [...inputobjects])
                            }} />
                            :
                            <InputBox field={items} value={inputobjects[objectcount][items]} handleChanges={(field, value) => {
                                inputobjects[objectcount][field] = value;
                                props.detailsUpdater(variableName, [...inputobjects])
                            }} />
                        }
                    </div>
                })}
                <div className="col">
                    {spaces(2)}
                    <button className='col btn btn-outline-danger btn-sm' onClick={() => {
                        inputobjects.splice(objectcount, 1);
                        setCount(count - 1);
                    }}>Remove
                    </button>
                </div>
            </div>
        );
        objectcounts++;
    }

    const addButton = <button className='btn btn-primary btn-lg' onClick={() => { setCount(count + 1); }}>ADD MORE</button>

    const tablehead = [
        <th key={0}>क्र.स.</th>,
        inputHeads.map((items, index) => {
            return <th key={index}>{items}</th>
        })
    ];

    const tablebody = inputobjects.map((items, index) => {
        return <tr key={index}>
            {['क्र.स.', ...inputHeads].map((item, index1) => {
                if (item.includes(",")) {
                    let subItems = item.split(",");
                    let values = subItems.map(subItem => items[subItem]);
                    let x = '';
                    if (!values.includes('') && !values.includes(undefined)) x = values.join(", ");
                    else x = '';
                    items[item] = x;
                }
                return index1 === 0 ? <td key={index1}>{index + 1}</td> : <td key={index1}>{items[item]}</td>
            })}
        </tr>
    });

    const table = <table className={props.borderless ? "borderless-table" : ""} >
        <thead>
            <tr>
                {tablehead}
            </tr>
        </thead>
        <tbody>
            {tablebody}
        </tbody>
    </table>

    const table_tippani = <table className="custom-table">
        <thead>
            <tr>
                {inputobjects.map((item, index) => (
                    <th key={index} className="underlined-title" >{item[tippaniPeople[0]]} </th>
                ))}
            </tr>
        </thead>
        <tbody>
            <tr>
                {inputobjects.map((item, index) => (
                    <td key={index}>
                        <br />
                        <div>....................</div>
                        <div>{item[tippaniPeople[1]]}</div>
                        <div>{item[tippaniPeople[2]]}</div>
                    </td>
                ))}
            </tr>
        </tbody>
    </table>

    useEffect(() => {
        // console.log('Table updated');
        // console.log('details[variableName]:', details[variableName]);
        // console.log('count:', count);
        // console.log({ inputHeads });
        props.setTable(props.signTable ? table_tippani : table)
    }, [details[variableName], count, inputHeads]);

    return (
        <div className='row'>
            <hr />
            {inputs}
            <hr />
            {(typeof details[variableName] !== 'undefined' || count === 0) && addButton}
        </div>
    );
}
