import React, { useRef, useState } from 'react'
import { FormValidation } from '../FormValidation';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import Applicant from '../ParagraphItems/Applicant';
import { ToastContainer } from 'react-toastify';
import Heading from '../ParagraphItems/Heading';
import { bold, date, programItems, sides, spaces, tippaniItems, tippaniPeople } from '../Variables';
import InputBox from '../InputBox';
import { convertNumberToText, currentFiscalYear, dateWithSlash, getApplicant, isValidDate, numbersWithCommas, userDetail } from '../Variables/TextVariables';
import TableEntry from '../ParagraphItems/TableEntry';
import SenderReceiver from '../ParagraphItems/SenderReceiver';
import Sender from '../ParagraphItems/Sender';
import Selection from '../ParagraphItems/Selection';
import { userDetails } from '../LoginDetails';
import DateItem from '../ParagraphItems/DateItem';
import ApplicationHeading from '../ParagraphItems/ApplicationHeading';

export default function Tippani(props) {
    const componentRef = useRef();
    const [table, setTable] = useState([]);
    const [activeDiv, setActiveDiv] = useState("first");
    const topic = props.title;
    const specificItems = props.specificItems.items;
    var topicObjects = specificItems[topic];
    if (typeof topicObjects === 'undefined') topicObjects = {};
    const samjhoutaItems = specificItems["सम्झौता"] || {};

    const availableOptions = ['कार्यक्रम सञ्चालन', 'भुक्तानी'];
    const selectedTitle = "selectedTitle";
    const selectedOption = topicObjects[selectedTitle] ? topicObjects[selectedTitle] : availableOptions[0];
    const selectedIndex = availableOptions.indexOf(selectedOption);
    const printAction = (
        <div className='printAction'>
            <FormValidation ref={componentRef} />
        </div>
    );
    const [selectedPerson, setSelectedPerson] = useState(true); // Default to 'Person'

    const handleToggle = () => {
        setSelectedPerson((prev) => (prev = !prev));
    };



    const updater = (field, value) => {
        const updatedValue = {};
        updatedValue[field] = value;
        props.specificItems.updater(topic, { ...specificItems[topic], ...updatedValue });
    };

    const copyFromSamjhouta = () => {
        const copiedItems = {};
        programItems.forEach(item => {
            if (samjhoutaItems[item]) {
                copiedItems[item] = samjhoutaItems[item];
            }
        });
        let item = "टिप्पणीमा समावेश व्यक्तिहरु";
        if (samjhoutaItems[item]) {
            copiedItems[item] = samjhoutaItems[item];
        }
        props.specificItems.updater(topic, { ...specificItems[topic], ...copiedItems });
        props.handleApplicant("", { "Name": props.person.receiver.Office, "Address": props.person.receiver.Address })

        // props.handleApplicant("Address", props.person.receiver.Address)


    };
    const tableEntry = (
        <div>
            <br />
            <h4>टिप्पणीमा समावेश व्यक्तिहरु</h4>
            <TableEntry signTable={true} setTable={setTable} inputHeads={tippaniPeople} variableName="टिप्पणीमा समावेश व्यक्तिहरु" details={topicObjects} detailsUpdater={updater} />
        </div>
    );
    const [start_text, set_start_text] = useState(" प्रस्तुत विषयमा यस कार्यालयको आ.व. २०८१।८२ को बजेट बाट भुक्तानी हुने गरी यस कार्यलयले प्रयोग गरेको २०८१ साल श्रावण, भदौ, असोज र कार्तिक महिनाको बिजुलीको खर्च बमोजिम रु. ७५७५  (अक्षरुपी सात हजार पाँच सय पचहत्तर रुपैयाँ मात्र ) को जम्मा बिल प्राप्त भएको छ ।");
    const [rules_text, set_rules_text] = useState("प्रचलित सार्वजनिक खरिद ऐन, २०६३ को दफा ४१(१) क मा तोकिएको रकमसम्मको फुटकर खरिदमा सोझै खरिद गर्न सकिने व्यवस्था गरिएको छ। साथै, सार्वजनिक खरिद नियमावली, २०६४ को नियम ८५ को उपनियम १ क अनुसार दश लाख रुपैयाँ सम्म लागत अनुमान भएको निर्माण कार्य वा मालसामान ऐनको दफा ४१(१) क को अधीनमा रही सोझै खरिद गर्न सकिने व्यवस्था रहेको छ। आर्थिक कार्यविधी तथा वित्तिय उत्तरदायित्व ऐन, २०७६ को दफा २४ (१) मा “अधिकार प्राप्‍त अधिकारीले स्वीकृत बजेट, कार्यक्रम र उपलब्ध स्रोत समेतको आधारमा खर्चको आदेश दिनुपर्नेछ” भन्ने व्यवस्था रहेको छ।");



    return (
        <div className='mukesh'>
            <button onClick={copyFromSamjhouta}>Copy from सम्झौता</button>
            <div className="row">
                <div className="col">
                    <DateItem date={props.date} selection={props.title} />
                </div>
            </div>
            <Applicant selection={props.title} applicant={props.applicant} handleApplicant={props.handleApplicant} />
            <div className="form-check form-switch d-flex align-items-center">
                <label className="form-check-label me-3" htmlFor="flexSwitchCheckDefault">
                    Person
                </label>
                {spaces(8)}
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={!selectedPerson}
                    onChange={handleToggle}
                    style={{ width: '60px' }} // Adjust width as needed
                />
                {spaces(4)}
                Institution
            </div>

            <ToastContainer />
            Tippani
            {printAction}
            <SenderReceiver person={props.person} handlePerson={props.handlePerson} senderHeading={sides[0]} receiverHeading={sides[1]} />
            <Selection selectionTitle={selectedTitle} options={availableOptions} details={topicObjects} detailsUpdater={updater} />

            {tippaniItems.map((items, index) => (
                <div key={index} required={true} className="col">
                    <InputBox
                        style={{ width: '100%' }}
                        field={items}
                        value={topicObjects[items]}
                        handleChanges={(field, value) => {
                            updater(field, field.endsWith("मिति") ? isValidDate(value) : value);
                        }}
                    />
                </div>
            ))}
            <div className="row">
                <div className="col">
                    {programItems.map((items, index) => (
                        <div key={index} required={true}>
                            <InputBox
                                style={{ width: '50%' }}
                                type={index === 2 ? "number" : "text"}
                                field={items}
                                value={topicObjects[items]}
                                handleChanges={(field, value) => {
                                    updater(field, field.endsWith("मिति") ? isValidDate(value) : value);
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div className="col">
                    {tableEntry}
                </div>
            </div>
            <div>
                प्रसंग वा विवरण

                <textarea
                    value={rules_text}
                    onChange={(e) => set_rules_text(e.target.value)}
                    style={{ width: '100%', height: '150px', }}
                />
            </div>

            <button onClick={() => setActiveDiv(activeDiv === "first" ? "second" : activeDiv === "second" ? "third" : activeDiv === "third" ? "fourth" : "first")}>
                {activeDiv === "first" ? "टिप्पणी" : activeDiv === "second" ? "कार्यादेश" : activeDiv === "third" ? "भुक्तानी निवेदन" : "नगरपालिकालाई चिठी"}
            </button>
            <div className='businessClose' contentEditable={true} ref={activeDiv === "first" ? componentRef : null}>
                <OfficeInformation noPatra={true} chalaniName='टिप्पणी संख्या :-' headerName='टिप्पणी र आदेश' />
                <Heading receiver={{}} topic={selectedOption} date={props.date} starter={"श्रीमान्"} />
                <div className="content" align='justify'>
                    {spaces(4)} प्रस्तुत विषयमा {bold(getApplicant())}ले यस {userDetails().office.नाम}मा {bold(topicObjects[programItems[0]])}{selectedIndex == 0 ? " गर्नका लागि प्रस्तावना" : (
            <>
                बापत बिल बमोजिमको रकम जम्मा रु. {numbersWithCommas(topicObjects[programItems[2]])} 
                {bold(" (अक्षरुपी ")}
                {bold(convertNumberToText(topicObjects[programItems[2]])+" रुपैयाँ मात्र) ")} 
                
                भुक्तानी गरिदिन निवेदन
            </>
        )
    }
    {selectedPerson ? " पेश गर्नु भएको छ ।" : " पेश गरेको छ ।"} <br /><br />
                    यस सन्दर्भमा, यस कार्यालयको आ.व. २{dateWithSlash(currentFiscalYear())} को स्वीकृत वार्षिक कार्यक्रम अन्तर्गत खर्च शीर्षक नं. {topicObjects[programItems[7]]}, "{topicObjects[programItems[8]]}" बजेट शीर्षकमा बजेट विनियोजन गरिएको छ। <br /><br />

                    {rules_text} <br /><br /> उपरोक्त कानूनी व्यवस्थाहरु समेतलाई दृष्टिगत गर्दै, खर्च शीर्षक नं. {topicObjects[programItems[7]]} "{topicObjects[programItems[8]]}" बजेट शीर्षकबाट भुक्तानी हुने गरी {selectedIndex == 0 ? <span>अनुमानित रकम रु. {numbersWithCommas(topicObjects[programItems[2]])} {bold("( अक्षरुपी " + convertNumberToText(topicObjects[programItems[2]]) + "रुपैयाँ मात्र )")} ननाघ्ने गरी उक्त कार्यक्रम उक्त संस्थालाई सञ्चालन गर्न दिन आदेशका लागि पेश गरेको छु ।</span> : <span>{bold(topicObjects[programItems[0]])}  बापत {bold(getApplicant())} को नाममा जम्मा रकम रु. {numbersWithCommas(topicObjects[programItems[2]])} {bold("(अक्षरुपी ")} {bold(convertNumberToText(topicObjects[programItems[2]]) + "रुपैयाँ मात्र )")} भुक्तानी दिन उपयुक्त देखिएकोले आर्थिक कार्यविधि तथा वित्तीय उत्तरदायित्व ऐन, २०७६ को दफा २४(१) बमोजिम स्वीकृतिका लागि निर्णयार्थ प्रस्तुत गर्दछु।
                    </span>}
                    <br />
                </div>
                {table}
            </div>

            <div className='businessClose' contentEditable={true} ref={activeDiv === "second" ? componentRef : null}>
                <OfficeInformation />
                <br />
                <Heading receiver={props.person.receiver} topic={"कार्यादेश"} date={props.date} />
                <br />
                <div className="content" align='justify'>

                    प्रस्तुत सम्बन्धमा मिति {dateWithSlash(props.date.currentDate)} गते को निर्णय र सम्झौता बमोजिम यस इनरुवा नगरपालिकामा {bold(topicObjects[programItems[0]])} नियमानुसार सम्झौता बमोजिम सम्पन्न गर्नका लागि यो कार्यादेश दिइएको छ ।

                    <br />

                </div>

                <br /> <br /> <br /> <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />
                </div>

            </div>

            <div className='businessClose mukesh' contentEditable={true} ref={activeDiv === "third" ? componentRef : null}>

                {<ApplicationHeading date={props.date} pramukh={"कार्यालय प्रमुख"} />}

                <br />
                <div className="content" align='justify'>

                    प्रस्तुत सम्बन्धमा {bold(topicObjects[programItems[0]])} बापत संलग्न कागजात र बिल बमोजिमको जम्मा रकम रु. {numbersWithCommas(topicObjects[programItems[2]])} {bold("(अक्षरुपी ")} {bold(convertNumberToText(topicObjects[programItems[2]]) + "रुपैयाँ मात्र )")} {selectedPerson ? "म" : ""} {props.applicant.Name}को नाममा भुक्तानी हुने व्यवस्था मिलाइदिनु हुन अनुरोध गर्दछु ।

                    <br />
                </div>

                <br /> <br /> <br /> <br />
                <div className='signature' align='right'>
                    <Sender person={{
                        sender: {
                            Name: props.applicant.Name,
                            Address: props.applicant.Address
                        }
                    }} />
                </div>

            </div>
            <div className='businessClose' contentEditable={true} ref={activeDiv === "fourth" ? componentRef : null}>
                <OfficeInformation />
                <br />
                <Heading receiver={props.person.receiver} topic={"भुक्तानी"} date={props.date} statuatory={"श्रीमान् "}  isPerson={true}/>
                <br />
                <div className="content" align='justify'>

                    प्रस्तुत सम्बन्धमा यस {userDetails().municipality.नाम} {userDetails().municipality.प्रकार} {userDetails().office.नाम}को आ.व. २{dateWithSlash(currentFiscalYear())} को स्वीकृत कार्यक्रम अन्तर्गत खर्च शीर्षक नं. {topicObjects[programItems[7]]}, "{topicObjects[programItems[8]]}" बजेट शीर्षक बाट खर्च हुनेगरी {topicObjects[programItems[0]]} बापत  {props.applicant.Name}को नाममा रु. {numbersWithCommas(topicObjects[programItems[2]])} {bold("(अक्षरुपी ")} {bold(convertNumberToText(topicObjects[programItems[2]]) + "रुपैयाँ मात्र )")} आर्थिक कार्यविधि तथा वित्तीय उत्तरदायित्व ऐन, २०७६ को दफा २४(१) बमोजिम भुक्तानी हुने व्यवस्था मिलाइदिनु हुन सिफारिससाथ अनुरोध छ ।

                    <br />

                </div>

                <br /> <br /> <br /> <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />
                </div>

            </div>
            
            <div>
            <iframe
            src="https://www.ashesh.com.np/panchang/widget.php?header_color=d1d0d1&amp;api=nc"
            frameBorder="0"
            scrolling="no"
            marginWidth="0"
            marginHeight="0"
            style={{
                border: 'none',
                overflow: 'hidden',
                width: '92%',
                height: '365px',
                borderRadius: '5px',
            }}
            allowTransparency="true"
            title="Panchang Widget"
        ></iframe>


            </div>

        </div>
    )
}
